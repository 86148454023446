<!--
 * @Author: LXG
 * @Date: 2021-05-14
 * @Editors: LXG
 * @LastEditTime: 2021-06-24
 * @Description: 政策办事指南内容
-->
<template>
    <div class="policy-guide-content">
        <!-- <Anchor
            v-if="guideType==1"
            show-ink
        >
            <AnchorLink
                v-for="(item,index) in anchorList"
                :key="index"
                :href="'#'+item.href"
                :title="item.title"
            >
            </AnchorLink>
        </Anchor> -->
        <!-- 简版 -->
        <div v-if="guideType==0">
            <div
                v-for="(item,index) in easyInfo.infoList"
                :key="index"
                class="pgc-item"
            >
                <h2 class="pgc-title">{{item.title}}</h2>
                <div class="easyItem-content">
                    <div
                        v-if="item.htmlKey"
                        v-html="policyBaseData[item.htmlKey]"
                    ></div>
                    <template>{{policyBaseData[item.valueKey]}}</template>
                </div>
            </div>
            <div class="pgc-item easy-sbdx">
                <h2 class="pgc-title">申报对象</h2>
                <div class="easyItem-content">
                    <el-table
                        :data="cpu_zzbzList"
                        stripe
                        border
                        size="medium"
                        row-class-name="table-sbdx-row"
                        header-row-class-name="table-sbdx-header"
                    >
                        <el-table-column
                            prop="bldx"
                            label="办理对象/注册类型"
                            align="center"
                        >
                            <template slot-scope="scope">
                                <span>
                                    {{getDictLabel('permit_sertype',scope.row.bldx)}}
                                    /
                                    {{getDictLabel('sys_enterprise_registertype',scope.row.qyzclx)}}
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="gssz"
                            label="资金测算公式"
                            header-align="center"
                        >
                            <template slot-scope="scope">
                                <div class="table-sbdx-cell-gssz">
                                    <p>
                                        {{scope.row.gssz}}
                                    </p>
                                    <TjIcon name="jisuanqi-m"></TjIcon>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="zzxe"
                            label="资金限额"
                            align="center"
                        >
                            <template slot-scope="scope">
                                <span v-if="scope.row.zzxe">
                                    {{scope.row.zzxe}}万元
                                </span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <p>注：本政策对企业行业、产业、注册类型、企业资质、经济指标、荣誉信息、资质认证等方面无要求。</p>
                </div>
            </div>
        </div>
        <!-- 详版 -->
        <div v-if="guideType==1">
            <div
                id="jbxx"
                class="pgc-item"
            >
                <h2 class="pgc-title">基本信息</h2>
                <InfoTable
                    :data="cpu_baseList"
                    labelWidth="140px"
                ></InfoTable>
            </div>
            <div
                id="zchx"
                class="pgc-item"
            >
                <h2 class="pgc-title">政策画像</h2>
                <InfoTable
                    :data="cpu_portraitList"
                    labelWidth="140px"
                ></InfoTable>
            </div>
            <div
                id="zzbz"
                class="pgc-item"
            >
                <h2 class="pgc-title">资助标准</h2>
                <div class="particular-content">
                    <div
                        v-for="(i, index) in cpu_zzbzList"
                        :key="index"
                        class="zzbz-item"
                    >
                        <div class="zzbz-title">
                            <h3 style="color: #000000;">{{index+1}}、{{i.zzqx}}</h3>
                            <el-button
                                type="text"
                                @click="toggleZzbzFold(i)"
                            >
                                {{i.unfold?'收起':'展开'}}
                                <i class="el-icon-caret-bottom"></i>
                            </el-button>
                        </div>
                        <el-table
                            v-show="i.unfold"
                            :data="[i]"
                            stripe
                            border
                            size="medium"
                        >
                            <el-table-column
                                prop="bldx"
                                label="办理对象"
                                width="140px"
                                align="center"
                            >
                                <template slot-scope="scope">
                                    <span>{{getDictLabel('permit_sertype', scope.row.bldx)}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="hylb"
                                label="行业类别"
                                width="140px"
                                align="center"
                            >
                                <template slot-scope="scope">
                                    <span>{{cpu_noText(cpu_hylbText(scope.row.hylb))}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="cylx"
                                label="产业类别"
                                width="140px"
                                align="center"
                            >
                                <template slot-scope="scope">
                                    <span>
                                        {{cpu_noText(getDictLabel('sys_enter_type',scope.row.cylx))}}
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="qyzclx"
                                label="企业注册类型"
                                width="140px"
                                align="center"
                            >
                                <template slot-scope="scope">
                                    <span>{{cpu_qyzclxText(scope.row.qyzclx)}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="zzrzyq"
                                label="企业资质要求"
                                align="center"
                            >
                                <template slot-scope="scope">
                                    <span>{{cpu_noText(scope.row.qyzz_text)}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="jjzb"
                                label="经济指标要求"
                                align="center"
                            >
                                <template slot-scope="scope">
                                    <span>
                                        {{cpu_noText(scope.row.jjzb)}}
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="ryxxyq"
                                label="荣誉信息要求"
                                align="center"
                            >
                                <template slot-scope="scope">
                                    <span>
                                        {{cpu_noText(scope.row.ryxxyq)}}
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="zzrzyq"
                                label="资质认证要求"
                                align="center"
                            >
                                <template slot-scope="scope">
                                    <span>
                                        {{cpu_noText(scope.row.zzrzyq)}}
                                    </span>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-table
                            v-show="i.unfold"
                            :data="[i]"
                            stripe
                            border
                            size="medium"
                        >
                            <el-table-column
                                prop="zzxe"
                                label="资金限额"
                                width="140px"
                                align="center"
                            >
                                <template slot-scope="scope">
                                    <span>{{scope.row.zzxe?scope.row.zzxe+'万元':cpu_noText()}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="xmlb"
                                label="项目类型"
                                width="140px"
                                align="center"
                            >
                                <template slot-scope="scope">
                                    <span>{{cpu_gsxmlxText(scope.row)}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="zzfs"
                                label="资助方式"
                                width="140px"
                                align="center"
                            >
                                <template slot-scope="scope">
                                    <span>{{cpu_gszzfsText(scope.row.zzfs)}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="csfs"
                                label="测算方法"
                                width="140px"
                                align="center"
                            >
                                <template slot-scope="scope">
                                    <div>{{cpu_gscsfsText(scope.row.csfs)}}</div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="gsjssm"
                                label="公式计算说明"
                                header-align="center"
                            >
                                <template slot-scope="scope">
                                    <div>计算参数：{{cpu_noText(scope.row.jscs)}}</div>
                                    <div>公式设置：{{cpu_noText(scope.row.gssz)}}</div>
                                    <div>样例：{{cpu_noText(scope.row.gsyl)}}</div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="rgpdsm"
                                label="人工判断说明"
                                header-align="center"
                            >
                                <template slot-scope="scope">
                                    <span>{{cpu_noText(scope.row.rgpdsm)}}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TjIcon from '@/components/tjIcon/TjIcon'
import InfoTable from './components/infoTable/InfoTable'
import { formatDate } from '@/utils/dateHandler.js'

export default {
    name: 'PolicyGuideContentEarly',
    components: {
        TjIcon,
        InfoTable,
    },
    props: {
        policyId: {
            type: String
        },
        // 办事指南版本
        //     '0': 简版
        //     '1': 详版
        guideType: {
            type: [Number, String],
            default: 0
        },
        // 政策原文信息
        policySourceData: {
            type: Object,
            default: () => { return {} }
        },
    },
    data() {
        return {
            easyInfo: {
                infoList: [
                    { title: '政策内容', valueKey: 'supportstandard' },
                ],
            },
            policyBaseData: {}, // 政策基本信息
            policyZzbzData: [], // 政策资助标准信息(可能多个资助标准)
            qxId: '', // 同一个政策不同资助标准的唯一ID 资助ID?
            policyPortraitData: {}, // 政策画像信息
            materialData: [], // 政策事项对应材料信息
            anchorList: [
                { href: 'jbxx', title: '基本信息' },
                { href: 'zzdx', title: '资助对象' },
                { href: 'zzbz', title: '资助标准' },
                { href: 'sqcl', title: '申请材料' },
                { href: 'sblc', title: '申报流程' },
                { href: 'zcyj', title: '政策依据' },
                { href: 'ckbl', title: '窗口办理' },
                { href: 'zxfs', title: '咨询方式' },
            ],
        }
    },
    computed: {
        ...mapGetters({
            getDictLabel: 'dictionary/getDictLabel',
            storeguideId: 'getCurrentGuideId',
        }),
        cpu_noText() {
            return function (val) {
                return val || '--'
            }
        },
        /**
         * @description: 资助标准列表
         * @return {Array}
         */
        cpu_zzbzList() {
            // 如果是从首页政策列表点击了具体的一个资助标准进来的
            // 页面会携带qxId过来，作为标识资助标准的唯一ID
            // 只显示这条资助标准
            // 否则，显示所有资助标准
            if (this.qxId) {
                let item = this.policyZzbzData.find(cv => cv.qxid === this.qxId)
                if (item) return [item]
            }
            return this.policyZzbzData
        },
        /**
         * @description: 基本信息列表
         * @return {Array}
         */
        cpu_baseList() {
            let list = []

            let zcsbqdrq = this.cpu_noText()
            let zcsbjzrq = this.cpu_noText()
            this.policyBaseData.start_time && (zcsbqdrq = formatDate(this.policyBaseData.start_time, 'yyyy年MM月dd日'))
            this.policyBaseData.endtime && (zcsbjzrq = formatDate(this.policyBaseData.endtime, 'yyyy年MM月dd日'))
            list.push({
                data: [
                    {
                        label: '政策项目编码',
                        value: this.policyBaseData.item_base_code,
                    },
                    {
                        label: '政策申报启动期限',
                        value: `${zcsbqdrq} ~ ${zcsbjzrq}`,
                    },
                ]
            })
            list.push({
                data: [
                    {
                        label: '项目受理部门',
                        value: this.policyBaseData.dept_name,
                    },
                    {
                        label: '政策来源',
                        value: this.cpu_noText(this.policySourceData.name),
                    },
                ]
            })
            list.push({
                data: [

                    {
                        label: '政策依据',
                        valueHTML: this.cpu_noText(this.policyBaseData.supportstandard),
                    },
                ]
            })

            return list
        },
        /**
         * @description: 政策画像列表
         * @return {Array}
         */
        cpu_portraitList() {
            let list = []

            let xmlb = ''
            if (this.policyPortraitData.gjz) {
                xmlb = this.policyPortraitData.gjz
                    .split(',')
                    .filter(cv => cv)
                    .map(cv => this.getDictLabel('sq_policy_xmlb', cv))
                    .join('，')
            }
            list.push({
                data: [
                    {
                        label: '支持方式',
                        value: this.cpu_noText(this.getDictLabel('sq_policy_zjlx', this.policyPortraitData.zcfs)),
                    },
                    {
                        label: '受纳税额度限制',
                        value: this.policyPortraitData.xxssnf == '1' ? '是' : '否',
                    },
                    {
                        label: '政策分类',
                        value: this.cpu_noText(xmlb),
                    },
                ]
            })
            list.push(
                {
                    data: [
                        { label: '注册要求', value: this.cpu_noText(this.policyPortraitData.zcyq) },
                        { label: '注册地', value: this.cpu_noText(this.policyPortraitData.zcd) },
                        { label: '纳税地', value: this.cpu_noText(this.policyPortraitData.nsd) },

                    ]
                },
                {
                    data: [
                        { label: '统计关系所在地', value: this.cpu_noText(this.policyPortraitData.tjgxszd) },
                        { label: '项目实施地点', value: this.cpu_noText(this.policyPortraitData.xmssdd) },
                        { label: '项目实施年度', value: this.cpu_noText(this.policyPortraitData.xxssnf) },
                    ]
                },
            )

            return list
        },
        /**
         * @description: 行业类别 文本
         * @param {String} hylb 行业类别 字典编码
         * @return {String}
         */
        cpu_hylbText() {
            return function (hylb) {
                if (!hylb) return this.cpu_noText()

                return hylb
                    .split(',')
                    .map(cv => this.getDictLabel('sys_category', cv))
                    .join(', ')

            }
        },
        /**
         * @description: 企业注册类型 文本
         * @param {String} qyzclx 企业注册类型字典编码
         * @return {String}
         */
        cpu_qyzclxText() {
            return function (qyzclx) {
                if (!qyzclx) return this.cpu_noText()

                return qyzclx
                    .split(',')
                    .map(cv => this.getDictLabel('sys_enterprise_registertype', cv))
                    .join(',')
            }
        },
        /**
         * @description: 资助标准公式 项目类型 文本
         * @param {Object} item 资助标准
         * @return {String}
         */
        cpu_gsxmlxText() {
            return function (item) {
                let str = this.cpu_noText()
                switch (item.xmlb) {
                    case '1':
                        str = '区级自核项目'
                        break
                    case '2':
                        str = '上级配套'
                        switch (item.xmhdzjzzc) {
                            case '1':
                                str += '(国家)'
                                break
                            case '2':
                                str += '(省级)'
                                break
                            case '3':
                                str += '(市级)'
                                break
                            default:
                                break
                        }
                        break
                    default:
                        break
                }
                return str
            }
        },
        /**
         * @description: 资助标准公式 资助方式 文本
         * @param {String} zzfs 资助方式
         * @return {String}
         */
        cpu_gszzfsText() {
            return function (zzfs) {
                switch (zzfs) {
                    case '1':
                        return '按比例'
                    case '2':
                        return '定额'
                    default:
                        return this.cpu_noText()
                }
            }
        },
        /**
         * @description: 资助标准公式 测算方法 文本
         * @param {String} csfs 测算方法
         * @return {String}
         */
        cpu_gscsfsText() {
            return function (csfs) {
                if (!csfs) return this.cpu_noText()

                let map = new Map([
                    ['1', '公式计算'],
                    ['2', '人工判断']
                ])
                return csfs.split(',').map(cv => map.get(cv)).join('，')
            }
        },
    },
    methods: {
        /**
         * @description 切换资助标准展开收起状态
         */
        toggleZzbzFold(item) {
            if (!Reflect.has(item, 'unfold')) {
                this.$set(item, 'unfold', true)
            } else {
                this.$set(item, 'unfold', !item.unfold)
            }
        },
        /**
         * @description: 查询政策基本信息
         * @param {String} id 政策ID
         */
        getPolicyBaseData(id) {
            let url = '/dev-api/cfgpermitconfig/query/data/find/cfg_permit_info_list';
            let params = {
                sxid: id,
            };
            this.$httpApi.post(url, params).then(res => {
                console.log('getPolicyBaseData:', res);
                if (res.data && !!res.data.length) {
                    this.policyBaseData = res.data[0]
                }
            }).catch(err => {
                console.log('getPolicyBaseData error:', err);
            })
        },
        /**
         * @description: 查询政策资助标准信息
         * @param {String} id 政策ID
         */
        getPolicyZzbzData(id) {
            let url = '/dev-api/sqzdManager/data/find/sq_policy_project_zzqx_list'
            let params = {
                sxid: id,
            }
            this.$httpApi.post(url, params).then(res => {
                console.log('getPolicyZzbzData:', res)
                if (res.data && !!res.data.length) {
                    this.policyZzbzData = res.data
                    // 获取 企业资质
                    for (let i = 0; i < this.policyZzbzData.length; i++) {
                        /**
                         * 20210624
                         * 后台配置是必填的，并且可以选 不限
                         * 对应的如果值为 '_ALL_' 则返回 不限
                         */
                        if (this.policyZzbzData[i].qyzz === '_ALL_') {
                            this.$set(this.policyZzbzData[i], 'qyzz_text', '不限')
                        } else {
                            let url = '/dev-api/sqzdManager/data/find/sq_policylabelname_query'
                            let params = { labelcodes: this.policyZzbzData[i].qyzz }
                            this.$httpApi.post(url, params).then(resp => {
                                if (resp.data && !!resp.data.length) {
                                    this.$set(this.policyZzbzData[i], 'qyzz_text', res.data.map(cv => cv.name).join('，'))
                                }
                            })
                        }
                    }
                }
            }).catch(err => {
                console.log('getPolicyZzbzData error:', err);
            })
        },
        /**
         * @description: 查询政策画像信息
         * @param {String} id 政策ID
         */
        getPolicyPortraitData(id) {
            let url = '/dev-api/cfgpermitconfig/query/data/find/cfg_permit_zchx';
            let params = {
                sxid: id,
            };
            this.$httpApi.post(url, params).then(res => {
                console.log('getPolicyPortraitData:', res);
                if (res.data && !!res.data.length) {
                    this.policyPortraitData = res.data[0]
                }
            }).catch(err => {
                console.log('getPolicyPortraitData error:', err);
            })
        },
        /**
         * @description: 查询政策事项对应材料信息
         * @param {String} sxid 事项ID
         */
        getMaterialData(sxid) {
            let url = '/dev-api/cfgpermitconfig/query/data/find/cfg_permit_materiallist'
            let params = { sxid }
            this.$httpApi.post(url, params).then(res => {
                console.log('getMaterialData:', res)
                this.materialData = res.data || []
            }).catch(err => {
                console.log('getMaterialData error:', err)
            })
        },
    },
    created() {
        this.$store.dispatch('dictionary/commonSetDic', {
            types: [
                'sq_policy_xmlb', // 项目类别
                'sq_policy_zjlx', // 扶持方式(支持方式)
                'permit_sertype', // 办理对象
                'sys_category', // 行业类别(国民经济分类)
                'sys_enter_type', // 产业类别
                'sys_enterprise_registertype', // 注册类型
            ]
        })
        this.$route.query.qxId && (this.qxId = this.$route.query.qxId)
    },
    watch: {
        policyId: {
            immediate: true,
            handler: function (newVal) {
                if (newVal) {
                    this.getPolicyBaseData(newVal)
                    this.getPolicyPortraitData(newVal)
                    this.getMaterialData(newVal)
                    this.getPolicyZzbzData(newVal)
                }
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.policy-guide-content {
    position: relative;
}
.pgc-item + .pgc-item {
    margin-top: 40px;
}
.pgc-title {
    padding-bottom: 10px;
    border-bottom: 3px solid #000000;
    margin-bottom: 10px;
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
        "Microsoft YaHei UI Light", "Microsoft YaHei UI", "Microsoft YaHei",
        "微软雅黑", Arial, sans-serif;
    font-weight: 700;
    color: #000000;
}
.easyItem-content {
    line-height: 2;
    text-indent: 28px;
}
.particular-content {
    line-height: 2;
    white-space: pre-wrap;
}
.text-i-28 {
    text-indent: 28px;
}
.particular-sblc {
    ::v-deep img {
        width: 100%;
        max-width: 700px;
    }
}
.easy-sbdx {
}

::v-deep .el-table {
    margin-bottom: 10px;

    .table-sbdx-row {
        &:hover {
            font-weight: normal;
        }
        .table-sbdx-cell-gssz {
            display: -webkit-flex;
            display: flex;
            align-items: center;

            p {
                flex: 1;
                padding-right: 20px;
            }
            .tj-icon {
                font-size: 20px;
                color: $col-theme;
                cursor: pointer;
            }
        }
    }
    .el-table__header-wrapper th {
        border-color: #e2e2e2;
        font-weight: normal;
        color: #000000;
        background-color: #f2f2f2 !important;
    }
    .el-table__body td {
        border-color: #e2e2e2;
        color: #000000;
    }
}

.zzbz-item {
    margin-bottom: 40px;

    .zzbz-title {
        display: -webkit-flex;
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        h3 {
            flex: 1;
        }
        ::v-deep .el-button {
            margin-left: 20px;
        }
    }
}

::v-deep .ivu-anchor {
    position: fixed;
    background-color: #ffffff;

    .ivu-anchor-ink {
        right: 0;
        left: auto;
    }
    .ivu-anchor-ink-ball {
        width: 4px;
        height: 20px;
        border: none;
        border-radius: 0;
        background-color: $col-theme;
        transform: translate(-50%, -2px);
    }
    .ivu-anchor-link {
        height: 40px;
        padding: 8px 16px 8px 0;

        &.ivu-anchor-link-active a {
            color: $col-theme;
        }
    }
}
@media screen and (min-width: 1366px) {
    ::v-deep .ivu-anchor {
        top: 26vh;
        left: calc(50% - 565px - 100px);
    }
}
@media screen and (min-width: 1600px) {
    ::v-deep .ivu-anchor {
        top: 26vh;
        left: calc(50% - 600px - 100px);
    }
}
@media screen and (min-width: 1920px) {
    ::v-deep .ivu-anchor {
        top: 26vh;
        left: calc(50% - 707px - 100px);
    }
}
@media screen and (max-width: 1365.98px) {
    ::v-deep .ivu-anchor {
        left: calc(50% + 512px - 100px);
        bottom: calc(#{$footerHeight} + 5vh);
    }
}
</style>
