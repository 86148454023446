<!--
 * @Author: LXG
 * @Date: 2021-05-14
 * @Editors: LXG
 * @LastEditTime: 2021-05-14
 * @Description: tj - render渲染
-->
<script>
export default {
    name: 'TjRender',
    props: {
        renderFn: {
            type: Function,
            default: function (h) { }
        },
    },
    render(h) {
        return this.renderFn(h)
    },
}
</script>