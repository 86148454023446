<!--
 * @Description: 信息表格
 * @Author: LXG
 * @Date: 2021-06-09
 * @Editors: LXG
 * @LastEditTime: 2021-06-24
-->
<template>
    <div class="info-table">
        <div class="wrap-infos">
            <div
                v-for="(row,rowIndex) in data"
                :key="rowIndex"
                class="row-item"
            >
                <div
                    v-if="row.header"
                    class="pad-1 row_header"
                >{{row.header}}</div>
                <div class="row_content">
                    <div
                        v-for="(col,colIndex) in row.data"
                        :key="colIndex"
                        class="col-item"
                    >
                        <label
                            class="pad-1 col-label"
                            :style="{'width':labelWidth}"
                        >{{col.label}}</label>
                        <div class="col-value">
                            <p
                                v-if="col.valueHTML"
                                v-html="col.valueHTML"
                                class="pad-1"
                            ></p>
                            <TjRender
                                v-else-if="col.valueRender"
                                :renderFn="col.valueRender"
                            ></TjRender>
                            <p
                                v-else
                                class="pad-1"
                            >{{col.value}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TjRender from '@/components/tjRender/TjRender'

export default {
    name: 'InfoTable',
    components: {
        TjRender,
    },
    props: {
        data: {
            type: Array,
            default: () => []
        },
        labelWidth: {
            type: String,
            default: '100px'
        },
    },
}
</script>

<style lang="scss" scoped>
.info-table {
    .info-table {
        .row_header,
        .col-label,
        .col-value {
            border-left: none;
        }

        .row-item:first-child {
            .row_header,
            .col-label,
            .col-value {
                border-top: none;
            }
        }
    }
}
.row-item {
    .row_header {
        text-align: center;
        color: #333333;
        background-color: #efefef;
    }
    .row_content {
        display: -webkit-flex;
        display: flex;
    }
}
.col-item {
    flex: 1;
    display: -webkit-flex;
    display: flex;
    .col-label {
        display: -webkit-flex;
        display: flex;
        align-items: center;
        background-color: #efefef;
        color: #333333;
    }
    .col-value {
        flex: 1;
        display: -webkit-flex;
        display: flex;
        align-items: center;
        color: #000000;
    }
}
.row_header,
.col-label,
.col-value {
    border: 1px solid #e2e2e2;
    // border: 1px solid $col-separator;
    margin-right: -1px;
    margin-bottom: -1px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
</style>